// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: clip;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.privacy-sentinel {
    height: 0;
    clear: both;
}

@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        transition: box-shadow .2s ease-in-out, transform .2s ease-in-out;
        z-index: 1000;

        &.stuck {
            .shadow();
            transform: translateY(-32px);

            .header-contact,
            .logo,
            .header-tools-wrapper {
                transform: translateY(16px);
            }
        }
    }
}

// TOP BAR
.top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: @header-color;
    font-weight: 700;
    position: relative;
    min-height: 48px;
    height: fit-content;
    padding: 0 32px;

    &:before {
        content: "";
        background-color: @sec-color;
        position: absolute;
        width: 101vw;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    .top-bar-close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: @font-color;
        width: 24px;

        &:after {
            content: "\e91c";
            .before();
        }

        &:hover:after {
            color: @header-color;
        }
    }
}


//SHOP HEADER
.shop-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 112px;
}

.header-contact {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: transform .2s ease-in-out;

    .facebook-link,
    .instagram-link {
        color: @header-color;

        &:before {
            .before();
        }

        &:hover:before {
            color: @main-color;
        }
    }

    .facebook-link:before {
        content: "\e91b";
    }

    .instagram-link:before {
        content: "\e91a";
    }

    .phone {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 24px;
        color: @header-color;

        &:before {
            content: "\e919";
            .before();
        }

        &:hover {
            color: @font-color;
        }
    }
}

.logo {
    display: flex;
    align-items: stretch;
    transform-origin: left top;
    transition: transform .2s ease-in-out;

    img {
        display: block;
        height: 48px;
        aspect-ratio: ~"241/48";
    }
}

// HEADER TOOLS WRAPPER
.header-tools-wrapper {
    display: flex;
    gap: 24px;
    transition: transform .2s ease-in-out;
    position: relative;
    z-index: 100; // for things to not pop up from below #search-engine on rwd
}

#cart-box,
#wishlist-box,
.log-button,
.search-engine-trigger,
.tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 36px;

    &:before {
        font-family: icomoon;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 32px;
        line-height: 1;
        color: @header-color;
    }

    em {
        color: #74676A;
        font-style: normal;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 1.2;
        letter-spacing: 0.1em;
        display: block;
        line-height: 14px;
    }

    &:hover {
        &:before {
            color: @font-color;
        }
    }
}

#cart-box i,
#wishlist-box:after {
    content: '';
    font-size: 0;
    position: absolute;
    top: 2px;
    left: calc(50% + 12px);
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background-color: @main-color;
    border-radius: 50%;
}

#cart-box {

    &:before {
        content: "\e915";
    }
}

#wishlist-box {

    &:before {
        content: "\e917";
    }

    &[data-wishlist-count="0"]:after {
        display: none;
    }
}

.log-button {

    &:before {
        content: "\e916";
    }
}

.search-engine-trigger {

    &:before {
        content: "\e918";
    }
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }
}


// SEARCH ENGINE
@media (min-width: 1280px) {
    #suggestions {
        right: -3px !important;
    }

    #search-engine {
        background: transparent;
        position: absolute;
        right: calc(100% - 38px);
        top: 0;
        width: 320px;
        z-index: 100;
        transition: all 0.2s ease-in-out 0.1s;
        opacity: 0;
        visibility: hidden;
        align-items: center;
        margin-right: 49px;
        transform: translateX(10px);

        &.show {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-last-phrases {
                    display: none;
                }
            }
        }

        &:hover {
            transition-delay: 0s;
            transition: all 0.1s ease-in-out;
        }

        &.show-with-last-phrases {

            &.no-products .no-products-info,
            a.suggestions-advanced-search {
                display: none;
            }

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-result-categories,
                .suggestion-result-producers,
                .suggestion-result-products {
                    display: none;
                }
            }

            a.suggestions-advanced-search {
                display: block;
            }
        }

        &.no-products {

            .no-products-info {
                display: block;

                & + .suggestion-column-wrapper .suggestion-left-column:not(.hidden) {
                    margin-top: 10px;
                }
            }
        }

        input[type="text"] {
            height: 48px;
            padding: 0 50px 0 22px;
            background-color: white;
            color: @header-color;
            font-size: 15px;
            font-family: @main-font-family;
            border: 1px solid @border-color;

            &::-webkit-input-placeholder {
                color: @font-color;
            }

            &::-moz-placeholder {
                color: @font-color;
            }

            &:-ms-input-placeholder {
                color: @font-color;
            }

            &:-moz-placeholder {
                color: @font-color;
            }
        }

        #searchbutton {
            position: absolute;
            top: 0;
            right: -49px;
            width: 42px;
            height: 58px;
            cursor: pointer;
            color: @header-color;
            background: white;
            display: flex;
            background: transparent;
            justify-content: center;
            align-items: center;
        }

        .search-engine-close {
            font-size: 0;
            width: 32px;
            aspect-ratio: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            cursor: pointer;

            &:after {
                content: "\e91c";
                .before();
                font-size: 32px;
            }
        }
    }

    body.show-search-engine {
        #search-engine {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 12px)";
    right: 0;
    background-color: white;
    width: 720px;
    padding: 22px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

.suggestion-column-wrapper {
    display: flex;
    width: 100%;
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;

    &.hidden {
        display: none;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &.hidden {
        display: none;
    }

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    margin-top: 12px;
    color: @header-color;

    &:hover {
        text-decoration: underline;
    }
}

// MAIN BAR
@media (min-width: 1280px) {
    .rwd-brands {
        display: none;
    }

    .tree-header {
        display: none;
    }

    #main-bar {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 48px;
        gap: 24px;

        a,
        .brands {
            color: white;
            font-weight: 700;
            display: block;
            white-space: nowrap;
            padding: 12px 0;

            &:hover {
                color: @main-color;
            }
        }

        &:after {
            content: "";
            background-color: @header-color;
            position: absolute;
            width: 101vw;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }

        .brands {
            cursor: pointer;

            > span {
                display: flex;
                align-items: center;
                gap: 8px;

                &:after {
                    content: "\e914";
                    font-family: icomoon;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            &:hover {

                > span:after {
                    transform: scaleY(-1);
                }

                .brands-wrapper {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: 0.2s;
                }
            }

            .brands-wrapper {
                opacity: 0;
                visibility: hidden;
                transition: all .2s ease-in-out;
                .shadow();
                width: 100%;
                position: absolute;
                top: calc(100%-2px);
                left: 0;

                .decal-anchor {
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    background: @header-color;
                    left: 0;

                    &:first-child {
                        top: 10px;
                        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(35,31,32,1) 80%);

                        &:before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 10px;
                            background: @header-color;
                            left: 0;
                            bottom: 100%;
                        }
                    }

                    &:last-child {
                        bottom: 0px;
                        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(35,31,32,1) 80%);
                    }
                }
            }

            .brands-hover {
                padding: 26px 35px 30px 35px;
                background: @header-color;
                gap: 16px;
                display: flex;
                flex-wrap: wrap;

                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                    padding: 8px 0;
                }

                strong {
                    text-align: center;
                }
            }
        }
    }

    .mainmenu {
        display: contents;
    }

    #tree,
    nav {
        display: contents;

        li {
            
        }

        .cms-rwd {
            display: none;
        }
    }

    .tree-top-menu {
        position: relative;
        z-index: 100;
        display: contents;

        small {
            display: none;
        }

        ul { //both
            position: absolute;
            background-color: @header-color;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: all .2s ease-in-out;
            min-width: 226px;
            padding: 18px 0 16px 0;

            a {
                padding: 8px 24px !important;
            }
        }

        .t0 { //in main bar
            position: relative;

            a {
            }

            &:hover {

                > ul {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    transition-delay: 0.2s;
                }
            }

            > ul {
                top: calc(100% - 2px);
                left: -24px;
            }
        }

        .parent.t0 > a {
            display: flex !important;
            gap: 8px;
            align-items: center;

            &:after {
                content: "\e914";
                font-family: icomoon;
                font-weight: normal;
                font-size: 20px;
                line-height: 24px;
            }
        }

        .parent.t0:hover {

            > a:after {
                transform: scaleY(-1);
            }
        }

        li:not(.t0) { //nested
            position: relative;

            &:hover {

                > ul {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    transition-delay: 0.2s;
                }
            }

            ul {
                top: -16px;
                left: 100%;
                padding: 16px 0;
                transform: translateX(-10px);

                &:before {
                    content: '';
                    height: 100%;
                    width: 15px;
                    background: transparent;
                    display: block;
                    right: calc(100% - 5px);
                    top: 0;
                    position: absolute;
                }
            }
        }

        .parent:not(.t0) > a {
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            &:after {
                content: "\e914";
                font-family: icomoon;
                font-weight: normal;
                font-size: 20px;
                line-height: 24px;
                transform: rotate(-90deg);
            }
        }

        li:hover {
            > a {
                display: none;
                color: @main-color !important;
            }
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    grid-column-gap: var(--prodGap);
    grid-row-gap: var(--prodGapVertical);
    width: 100%;
}

.product {

    &:last-child,
    &:nth-child(4n) {

        &:after {
            display: none;
        }
    }
}

.main-page-products {

    &.promo-bestseller {

        .product:nth-child(9),
        .product:nth-child(10) {
            display: none;
        }
    }

    &.promo-new {
        .product:nth-child(5),
        .product:nth-child(6) {
            display: none;
        }
    }
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto auto 1fr;
    border-radius: var(--borderRadius);

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(1 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:after {
        position: absolute;
        right: calc(-0.5 * var(--prodGap));
        top: 0;
        content: '';
        height: 100%;
        width: 1px;
        background: @border-color;
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            opacity: 0;
        }
    }
}

.product-badges,
.detail-badges {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 700;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    text-align: center;

    span {
        background-color: @header-color;
        padding: 2px 6px;

        &.bestseller-badge {
            background-color: @main-color;
        }
    }
}

.promo-badge,
.detail-promo-badge {
    background-color: @error-color !important;
}

.product-wishlist {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    aspect-ratio: 1;
    border-radius: 50px;
    background-color: @sec-color;
    z-index: 2;
    color: @header-color;

    &:after {
        content: "\e917";
        .before();
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        color: white;
        background: @main-color;
    }

    &.existing {
        background-color: @header-color;
        color: @border-color;
        transition: background-color 0.05s ease-in-out;
    }
}

a.product-name {
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    padding-top: 24px;
    font-weight: 700;

    &:hover {
        text-decoration: underline;
    }

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-desc {
    font-size: 14px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    height: ~"calc(3 * 14 * 1.5)";
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 5px;
    z-index: 1;
    padding-top: 22px;
    align-self: end;
}

.product-price {
    height: 24px;

    b, del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 700;
        color: @header-color;
    }

    del {
        font-size: 14px;
        color: @font-color;
        margin-left: 10px;
    }
}

.product-add {
    border: 1px solid @main-color;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: @header-color;
    background: white;
    line-height: 1.2;
    font-weight: 700;
    font-size: 14px;
    gap: 10px;
    padding: 5px 12px;

    &:before {
        content: "\e915";
        .before();
        font-size: 20px;
    }

    &:hover {
        border-color: @header-color;
    }

    &.disabled {
        display: none;
    }
}

@media (min-width: 1280px) {
    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding-top: calc(var(--rowGap) / 4);
    padding-bottom: calc(var(--rowGap) / 3);
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-bottom: calc(-1 * var(--rowGap));

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 6px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @header-color;
        display: block;
        height: calc(var(---fontSize) * var(--lineHeight));
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @light-bg;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}